import { ToastContainer, toast, Flip, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props extends ToastContainerProps {
    limit?: number
}


export function emit(props: Props, text: string, type?: "success" | "error" | "warn" | "info") {
    toast[type || "success"](text, {
        position: "top-right",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        transition: Flip,
        style: {
            color: '#fff',
            fontSize: '15px',
            borderRadius: '4px'
        },
        ...props
    })

}

function Notification(props: Props) {
    return <ToastContainer limit={props.limit} {...props} />
}


export default Notification

